import { Manifest } from '../index.d';

const manifest: Manifest = {
  'sonic-tooltip': {
    dist: ['sonic-tooltip.js'],
  },
  'he-qdf': {
    dependencies: ['sonic-tooltip'],
    dist: ['sonic-qdf.js'],
    selector: 'he-qdf',
  },
  'he-qdf-usnews': {
    dependencies: ['sonic-tooltip'],
    dist: ['sonic-qdf-usnews.js'],
    selector: 'he-qdf-usnews',
  },
  'he-qdf-affiliate': {
    dependencies: ['sonic-tooltip'],
    dist: ['sonic-qdf-affiliate.js'],
    selector: 'he-qdf-affiliate',
  },
  'affiliate-qdf': {
    dependencies: ['sonic-tooltip'],
    dist: ['sonic-affiliate-qdf.js'],
    selector: 'affiliate-qdf',
  },
  'he-editorial-listings': {
    dependencies: ['sonic-tooltip'],
    dist: ['sonic-editorial-listings.js'],
    selector: 'he-editorial-listings',
  },
  'he-custom-editorial-listings': {
    dependencies: ['sonic-tooltip'],
    dist: ['sonic-partner-editorial-listings.js'],
    selector: 'he-custom-editorial-listings',
  },
  'he-usnews-editorial-listings': {
    dependencies: ['sonic-tooltip'],
    dist: ['sonic-el-usnews.js'],
    selector: 'he-usnews-editorial-listings',
  },
  'he-qdf-wrapper': {
    dependencies: ['sonic-tooltip', 'he-qdf'],
    dist: ['sonic-qdf-wrapped.js'],
    selector: 'he-qdf-wrapper',
  },
  'he-affiliate-editorial-listings': {
    dependencies: ['sonic-tooltip'],
    dist: ['sonic-el-affiliate.js'],
    selector: 'he-affiliate-editorial-listings',
  },
};

export default manifest;
